/* eslint-disable max-lines */
/* eslint-disable max-classes-per-file */
/* eslint-disable max-lines */
import { prop, Ref } from '@typegoose/typegoose';

import { Batch, Customer, Declarant, Template, User } from '@e-origin/shared';

import { TemplateType, TransitCustomsFlowType, TransitLogType, TransitStatus } from '../enums';
import { BaseModel } from './base.model';
import { DomainCollection } from './domain.collection';

export class TransitCreate {
  name: string;

  customer: string;

  template: string;

  MRN?: string;
}

export enum TransitGroup {
  ARRIVAL = 'A',
  DEPARTURE = 'D',
}

export class TransitGeneralInfo {
  @prop({ required: true, enum: TransitStatus, type: String, default: TransitStatus.NOT_SENT })
  status: TransitStatus;

  @prop({ enum: TransitGroup })
  group?: TransitGroup;

  @prop()
  totalAmountGuaranteeToBeCovered?: number;
}

class ContactPerson {
  @prop()
  name?: string;

  @prop()
  phoneNumber?: string;

  @prop()
  eMailAddress?: string;
}

class Address {
  @prop()
  streetAndNumber?: string;

  @prop()
  postcode?: string;

  @prop()
  city?: string;

  @prop()
  country?: string;

  @prop()
  houseNumber?: string;
}

export class TransitDocument {
  @prop()
  sequenceNumber?: string;

  @prop()
  type?: string;

  @prop()
  referenceNumber?: string;

  @prop()
  documentLineItemNumber?: string;

  @prop()
  goodsItemNumber?: string;

  @prop()
  typeOfPackages?: string;

  @prop()
  numberOfPackages?: string;

  @prop()
  measurementUnitAndQualifier?: string;

  @prop()
  quantity?: string;

  @prop()
  complementOfInformation?: string;

  @prop()
  code?: string;

  @prop()
  text?: string;
}

class TransitOperation {
  @prop()
  LRN?: string;

  @prop()
  declarationType?: string;

  @prop()
  additionalDeclarationType?: string;

  @prop()
  TIRCarnetNumber?: string;

  @prop()
  presentationOfTheGoodsDateAndTime?: string;

  @prop()
  security?: string;

  @prop()
  reducedDatasetIndicator?: string;

  @prop()
  specificCircumstanceIndicator?: string;

  @prop()
  communicationLanguageAtDeparture?: string;

  @prop()
  bindingItinerary?: string;

  @prop()
  limitDate?: string;

  @prop()
  MRN?: string;

  @prop()
  arrivalNotificationDateAndTime?: string;

  @prop()
  simplifiedProcedure?: string;

  @prop()
  incidentFlag?: string;

  @prop()
  discharge?: string;

  @prop()
  voletPageNumber?: string;

  @prop()
  otherThingsToReport?: string;

  @prop()
  releaseRequested?: boolean;
}

class Authorisation {
  @prop()
  sequenceNumber?: string;

  @prop()
  type?: string;

  @prop()
  referenceNumber?: string;
}

class TransitCustomsOffice {
  @prop()
  sequenceNumber?: string;

  @prop()
  referenceNumber?: string;

  @prop()
  arrivalDateAndTimeEstimated?: string;
}

class TraderAtDestination {
  @prop()
  identificationNumber?: string;

  @prop()
  communicationLanguageAtDestination?: string;
}

class HolderOfTheTransitProcedure {
  @prop()
  identificationNumber?: string;

  @prop()
  TIRHolderIdentificationNumber?: string;

  @prop()
  name?: string;

  @prop({ _id: false, type: Address })
  Address?: Address;

  @prop({ _id: false, type: ContactPerson })
  ContactPerson?: ContactPerson;
}

class Representative {
  @prop()
  identificationNumber?: string;

  @prop()
  status?: string;

  @prop({ _id: false, type: ContactPerson })
  ContactPerson?: ContactPerson;
}

class GuaranteeReference {
  @prop()
  sequenceNumber?: string;

  @prop()
  GRN?: string;

  @prop()
  accessCode?: string;

  @prop()
  amountToBeCovered?: string;

  @prop()
  currency?: string;
}

class Guarantee {
  @prop()
  sequenceNumber?: string;

  @prop()
  guaranteeType?: string;

  @prop()
  otherGuaranteeReference?: string;

  @prop({ _id: false, type: [GuaranteeReference] })
  GuaranteeReference?: GuaranteeReference[];
}

class UnloadingRemark {
  @prop()
  conform?: string;

  @prop()
  unloadingCompletion?: string;

  @prop()
  unloadingDate?: string;

  @prop()
  stateOfSeals?: string;

  @prop()
  unloadingRemark?: string;
}

// TransitConsignment nested classes
class ConsignorOrConsigneeOrCarrier {
  @prop()
  identificationNumber?: string;

  @prop()
  name?: string;

  @prop({ _id: false, type: Address })
  Address?: Address;

  @prop({ _id: false, type: ContactPerson })
  ContactPerson?: ContactPerson;
}

class AdditionalSupplyChainActor {
  @prop()
  sequenceNumber?: string;

  @prop()
  role?: string;

  @prop()
  identificationNumber?: string;
}

class Seal {
  @prop()
  sequenceNumber?: string;

  @prop()
  identifier?: string;
}

class GoodsReference {
  @prop()
  sequenceNumber?: string;

  @prop()
  declarationGoodsItemNumber?: string;
}

class TransportEquipment {
  @prop()
  sequenceNumber?: string;

  @prop()
  containerIdentificationNumber?: string;

  @prop()
  numberOfSeals?: string;

  @prop({ _id: false, type: [Seal] })
  Seal?: Seal[];

  @prop({ _id: false, type: [GoodsReference] })
  GoodsReference?: GoodsReference[];
}

class GNSS {
  @prop()
  latitude?: string;

  @prop()
  longitude?: string;
}

class EconomicOperator {
  @prop()
  identificationNumber?: string;
}

class TransitLocationOfGoods {
  @prop()
  typeOfLocation?: string;

  @prop()
  qualifierOfIdentification?: string;

  @prop()
  authorisationNumber?: string;

  @prop()
  additionalIdentifier?: string;

  @prop()
  UNLocode?: string;

  @prop()
  country?: string;

  @prop({ _id: false, type: TransitCustomsOffice })
  CustomsOffice?: TransitCustomsOffice;

  @prop({ _id: false, type: GNSS })
  GNSS?: GNSS;

  @prop({ _id: false, type: EconomicOperator })
  EconomicOperator?: EconomicOperator;

  @prop({ _id: false, type: Address })
  Address?: Address;

  @prop({ _id: false, type: Address })
  PostcodeAddress?: Address;

  @prop({ _id: false, type: ContactPerson })
  ContactPerson?: ContactPerson;
}

class DepartureTransportMeans {
  @prop()
  sequenceNumber?: string;

  @prop()
  typeOfIdentification?: string;

  @prop()
  identificationNumber?: string;

  @prop()
  nationality?: string;
}

class CountryOfRoutingOfConsignment {
  @prop()
  sequenceNumber?: string;

  @prop()
  country?: string;
}

class ActiveBorderTransportMeans {
  @prop()
  sequenceNumber?: string;

  @prop()
  customsOfficeAtBorderReferenceNumber?: string;

  @prop()
  typeOfIdentification?: string;

  @prop()
  identificationNumber?: string;

  @prop()
  nationality?: string;

  @prop()
  conveyanceReferenceNumber?: string;
}

class PlaceOfLoadingOrUnloading {
  @prop()
  UNLocode?: string;

  @prop()
  country?: string;

  @prop()
  location?: string;
}

class TransportCharges {
  @prop()
  methodOfPayment?: string;
}

// TransitConsignment.TransitHouseConsignment nested classes
class CommodityCode {
  @prop()
  hsCode?: string;

  @prop()
  harmonizedSystemSubHeadingCode?: string;

  @prop()
  combinedNomenclatureCode?: string;

  @prop()
  initialAmountToPayForVat?: string;

  @prop()
  initialAmountToPayForDuties?: string;
}

class DangerousGoods {
  @prop()
  sequenceNumber?: string;

  @prop()
  UNNumber?: string;
}

class GoodsMeasure {
  @prop()
  grossMass?: string;

  @prop()
  netMass?: string;

  @prop()
  supplementaryUnits?: string;
}

class Commodity {
  @prop()
  descriptionOfGoods?: string;

  @prop()
  CUSCode?: string;

  @prop({ _id: false, type: CommodityCode })
  CommodityCode?: CommodityCode;

  @prop({ _id: false, type: [DangerousGoods] })
  DangerousGoods?: DangerousGoods[];

  @prop({ _id: false, type: GoodsMeasure })
  GoodsMeasure?: GoodsMeasure;
}

class Packaging {
  @prop()
  sequenceNumber?: string;

  @prop()
  typeOfPackages?: string;

  @prop()
  numberOfPackages?: string;

  @prop()
  shippingMarks?: string;
}

export class ConsignmentItem {
  @prop()
  goodsItemNumber?: string;

  @prop()
  declarationGoodsItemNumber?: string;

  @prop()
  declarationType?: string;

  @prop()
  countryOfDispatch?: string;

  @prop()
  countryOfDestination?: string;

  @prop()
  referenceNumberUCR?: string;

  @prop()
  releaseType?: string;

  @prop({ _id: false, type: [AdditionalSupplyChainActor] })
  AdditionalSupplyChainActor?: AdditionalSupplyChainActor[];

  @prop({ _id: false, type: Commodity })
  Commodity?: Commodity;

  @prop({ _id: false, type: [Packaging] })
  Packaging?: Packaging[];

  @prop({ _id: false, type: [TransitDocument] })
  PreviousDocument?: TransitDocument[];

  @prop({ _id: false, type: [TransitDocument] })
  SupportingDocument?: TransitDocument[];

  @prop({ _id: false, type: [TransitDocument] })
  AdditionalReference?: TransitDocument[];

  @prop({ _id: false, type: [TransitDocument] })
  AdditionalInformation?: TransitDocument[];
}

export class TransitHouseConsignmentBatch {
  @prop({ ref: () => Batch })
  id: Ref<Batch>;

  @prop()
  name: string;
}

export class TransitHouseConsignment {
  @prop()
  sequenceNumber?: string;

  @prop()
  countryOfDispatch?: string;

  @prop()
  grossMass?: string;

  @prop()
  referenceNumberUCR?: string;

  @prop()
  releaseType?: string;

  @prop({ _id: false, type: ConsignorOrConsigneeOrCarrier })
  Consignor?: ConsignorOrConsigneeOrCarrier;

  @prop({ _id: false, type: ConsignorOrConsigneeOrCarrier })
  Consignee?: ConsignorOrConsigneeOrCarrier;

  @prop({ _id: false, type: [AdditionalSupplyChainActor] })
  AdditionalSupplyChainActor?: AdditionalSupplyChainActor[];

  @prop({ _id: false, type: [DepartureTransportMeans] })
  DepartureTransportMeans?: DepartureTransportMeans[];

  @prop({ _id: false, type: [TransitDocument] })
  PreviousDocument?: TransitDocument[];

  @prop({ _id: false, type: [TransitDocument] })
  SupportingDocument?: TransitDocument[];

  @prop({ _id: false, type: [TransitDocument] })
  TransportDocument?: TransitDocument[];

  @prop({ _id: false, type: [TransitDocument] })
  AdditionalReference?: TransitDocument[];

  @prop({ _id: false, type: [TransitDocument] })
  AdditionalInformation?: TransitDocument[];

  @prop({ _id: false, type: TransportCharges })
  TransportCharges?: TransportCharges;

  @prop({ _id: false, type: [ConsignmentItem], default: [] })
  ConsignmentItem?: ConsignmentItem[];

  @prop({ _id: false, type: [TransitHouseConsignmentBatch] })
  batches?: TransitHouseConsignmentBatch[];
}

class Endorsement {
  @prop()
  date?: string;

  @prop()
  authority?: string;

  @prop()
  place?: string;

  @prop()
  country?: string;
}

class TransportMeans {
  @prop()
  typeOfIdentification?: string;

  @prop()
  identificationNumber?: string;

  @prop()
  nationality?: string;
}

class Transhipment {
  @prop()
  containerIndicator?: string;

  @prop({ _id: false, type: TransportMeans })
  TransportMeans?: TransportMeans;
}

export class Incident {
  @prop()
  id?: number;

  @prop()
  sequenceNumber?: string;

  @prop()
  code?: string;

  @prop()
  text?: string;

  @prop({ _id: false, type: Endorsement })
  Endorsement?: Endorsement;

  @prop({ _id: false, type: TransitLocationOfGoods })
  Location?: TransitLocationOfGoods;

  @prop({ _id: false, type: [TransportEquipment] })
  TransportEquipment?: TransportEquipment[];

  @prop({ _id: false, type: Transhipment })
  Transhipment?: Transhipment;
}

export class TransitConsignment {
  @prop()
  countryOfDispatch?: string;

  @prop()
  countryOfDestination?: string;

  @prop()
  containerIndicator?: string;

  @prop()
  inlandModeOfTransport?: string;

  @prop()
  modeOfTransportAtTheBorder?: string;

  @prop()
  grossMass?: string;

  @prop()
  totalNumberOfPackages?: string;

  @prop()
  totalQuantity?: string;

  @prop()
  totalNumberOfSeals?: string;

  @prop()
  referenceNumberUCR?: string;

  @prop({ _id: false, type: ConsignorOrConsigneeOrCarrier })
  Carrier?: ConsignorOrConsigneeOrCarrier;

  @prop({ _id: false, type: ConsignorOrConsigneeOrCarrier })
  Consignor?: ConsignorOrConsigneeOrCarrier;

  @prop({ _id: false, type: ConsignorOrConsigneeOrCarrier })
  Consignee?: ConsignorOrConsigneeOrCarrier;

  @prop({ _id: false, type: ConsignorOrConsigneeOrCarrier })
  ConsigneeActual?: ConsignorOrConsigneeOrCarrier;

  @prop({ _id: false, type: [AdditionalSupplyChainActor] })
  AdditionalSupplyChainActor?: AdditionalSupplyChainActor[];

  @prop({ _id: false, type: [TransportEquipment] })
  TransportEquipment?: TransportEquipment[];

  @prop({ _id: false, type: TransitLocationOfGoods })
  LocationOfGoods?: TransitLocationOfGoods;

  @prop({ _id: false, type: DepartureTransportMeans })
  DepartureTransportMeans?: DepartureTransportMeans[];

  @prop({ _id: false, type: [CountryOfRoutingOfConsignment] })
  CountryOfRoutingOfConsignment?: CountryOfRoutingOfConsignment[];

  @prop({ _id: false, type: [ActiveBorderTransportMeans] })
  ActiveBorderTransportMeans?: ActiveBorderTransportMeans[];

  @prop({ _id: false, type: PlaceOfLoadingOrUnloading })
  PlaceOfLoading?: PlaceOfLoadingOrUnloading;

  @prop({ _id: false, type: PlaceOfLoadingOrUnloading })
  PlaceOfUnloading?: PlaceOfLoadingOrUnloading;

  @prop({ _id: false, type: [TransitDocument] })
  PreviousDocument?: TransitDocument[];

  @prop({ _id: false, type: [TransitDocument] })
  SupportingDocument?: TransitDocument[];

  @prop({ _id: false, type: [TransitDocument] })
  TransportDocument?: TransitDocument[];

  @prop({ _id: false, type: [TransitDocument] })
  AdditionalReference?: TransitDocument[];

  @prop({ _id: false, type: [TransitDocument] })
  AdditionalInformation?: TransitDocument[];

  @prop({ _id: false, type: TransportCharges })
  TransportCharges?: TransportCharges;

  @prop({ _id: false, type: [TransitHouseConsignment] })
  HouseConsignment?: TransitHouseConsignment[];
}

// TransitCustomsState nested classes
export class TransitControlResult {
  @prop()
  code?: string;

  @prop()
  date?: string;

  @prop()
  remarks?: string;

  @prop()
  controlledBy?: string;

  @prop()
  text?: string;
}

export class TransitCustomsDeclarationRejection {
  @prop()
  businessRejectionType?: string;

  @prop()
  rejectionCode?: string;

  @prop()
  rejectionDate?: string;

  @prop()
  rejectionReason?: string;
}

export class TransitCustomsLog {
  @prop()
  customsMessageCode?: string;

  @prop()
  parsedAt: Date;

  @prop()
  issuedAt: Date;

  @prop()
  log?: string;

  @prop()
  logFile: string;

  @prop({ enum: TransitLogType, type: String })
  type: TransitLogType;
}

export class TransitFunctionalError {
  @prop()
  errorCode?: string;

  @prop()
  errorPointer?: string;

  @prop()
  errorReason?: string;

  @prop()
  remarks?: string;

  @prop()
  sequenceNumber?: string;

  @prop()
  originalAttributeValue?: string;
}

export class TransitInvalidationRequest {
  @prop()
  invalidationReason?: string;

  @prop()
  invalidationRequestDate?: Date;

  @prop()
  correlationId?: string;
}

export class TransitInvalidationDecision {
  @prop()
  invalidationDecisionDate?: Date;

  @prop()
  invalidationRequestDate?: Date;

  @prop()
  decision?: string;

  @prop()
  invalidationInitiatedByCustoms?: string;

  @prop()
  invalidationReason?: string;

  @prop()
  correlationId?: string;
}

export class TransitRelease {
  @prop()
  dateOfRelease?: string;
}

export class TransitNoRelease {
  @prop()
  rejectionDate?: string;

  @prop()
  noReleaseMotivationCode?: string;

  @prop()
  noReleaseMotivationText?: string;
}

export class TransitWriteOff {
  @prop()
  writeOffDate?: string;
}

export class TransitReleaseDestination {
  @prop()
  releaseDate?: string;

  @prop()
  releaseIndicator?: string;

  @prop()
  sequenceNumber?: string;

  @prop({ _id: false, type: TransitHouseConsignment })
  HouseConsignment?: TransitHouseConsignment;
}

class InvalidGuaranteeReason {
  @prop()
  sequenceNumber?: string;

  @prop()
  code?: string;

  @prop()
  text?: string;
}

class INVGuaranteeReference {
  @prop()
  sequenceNumber?: string;

  @prop()
  GRN?: string;

  @prop({ _id: false, type: [InvalidGuaranteeReason] })
  InvalidGuaranteeReason?: InvalidGuaranteeReason[];
}

class UnloadPermission {
  @prop()
  unloadPermissionDate?: string;

  @prop()
  continueUnloading?: string;

  @prop({ _id: false, type: [Incident] })
  Incident?: Incident[];
}

export class TransitTypeOfControls {
  @prop()
  type: string;

  @prop()
  remarks: string;
}

export class TransitRequestedDocuments {
  @prop()
  type: string;

  @prop()
  ccQualifier?: string;

  @prop()
  referenceNumber?: string;

  @prop()
  description: string;
}

export class TransitInControl {
  @prop()
  notificationDate: string;

  @prop()
  controlType: string;

  @prop()
  customsRegistrationNumber: string;

  @prop()
  notificationType: string;

  @prop()
  anticipatedControlDate: string;

  @prop()
  text: string;

  @prop({ _id: false, type: TransitTypeOfControls })
  typeOfControls: TransitTypeOfControls[];

  @prop({ _id: false, type: TransitRequestedDocuments })
  requestedDocuments: TransitRequestedDocuments[];
}

export class Discrepancy {
  @prop()
  date: Date;

  @prop()
  text: string;
}

export class CustomsOfficeOfEnquiryAtDeparture {
  @prop()
  referenceNumber: string;
}

export class RequestNonArrived {
  @prop()
  requestDate: Date;

  @prop()
  limitForResponseDate: Date;

  @prop({ _id: false, type: CustomsOfficeOfEnquiryAtDeparture })
  customsOfficeOfEnquiryAtDeparture: CustomsOfficeOfEnquiryAtDeparture;
}

export class Enquiry {
  @prop()
  TC11DeliveryDate: Date;

  @prop()
  text: string;
}

export class TransitCustomsState {
  @prop({ _id: false, type: TransitControlResult })
  controlResult?: TransitControlResult;

  @prop()
  correlationId?: string;

  @prop({ _id: false, type: TransitCustomsDeclarationRejection })
  customsDeclarationRejection?: TransitCustomsDeclarationRejection;

  @prop({ _id: false, type: [TransitCustomsLog] })
  customsLogs?: TransitCustomsLog[];

  @prop()
  dateOfAcceptance?: Date;

  @prop({ _id: false, type: [TransitFunctionalError] })
  functionalError?: TransitFunctionalError[];

  @prop({ _id: false, type: TransitInvalidationRequest })
  invalidationRequest?: TransitInvalidationRequest;

  @prop({ _id: false, type: TransitInvalidationDecision })
  invalidationDecision?: TransitInvalidationDecision;

  @prop({ _id: false, type: TransitRelease })
  release?: TransitRelease;

  @prop({ _id: false, type: TransitNoRelease })
  noRelease?: TransitNoRelease;

  @prop({ required: false, enum: TransitCustomsFlowType, type: String })
  flow?: TransitCustomsFlowType;

  @prop()
  sentTo?: string;

  @prop({ _id: false, type: TransitWriteOff })
  writeOff?: TransitWriteOff;

  @prop({ _id: false, type: TransitReleaseDestination })
  releaseDestination?: TransitReleaseDestination;

  @prop({ _id: false, type: [INVGuaranteeReference] })
  INVGuaranteeReference?: INVGuaranteeReference[];

  @prop({ _id: false, type: UnloadPermission })
  unloadPermission?: UnloadPermission;

  @prop({ _id: false, type: TransitInControl })
  inControl: TransitInControl;

  @prop({ _id: false, type: [Discrepancy] })
  discrepancies: Discrepancy[];

  @prop({ _id: false, type: RequestNonArrived })
  requestNonArrived: RequestNonArrived;
}

@DomainCollection({ schemaOptions: { timestamps: true } })
export class Transit extends BaseModel {
  @prop({ required: true })
  name: string;

  @prop({ ref: () => Customer })
  customer: Ref<Customer>;

  @prop({ ref: () => Template })
  template: Ref<Template>;

  @prop({ enum: [TemplateType.TRANSIT], type: String, default: TemplateType.TRANSIT })
  templateType: TemplateType.TRANSIT;

  @prop({ default: 20 })
  transactionType: number;

  @prop({ ref: () => Declarant })
  declarant: Ref<Declarant>;

  @prop({ default: false })
  deleted: boolean;

  @prop({ required: true, ref: () => User })
  createdBy: Ref<User>;

  @prop()
  counter?: number;

  @prop({ ref: 'Batch' })
  batches?: Ref<Batch>[];

  @prop({ default: false })
  isArchived?: boolean;

  @prop({ _id: false, type: TransitGeneralInfo })
  generalInfo: TransitGeneralInfo;

  @prop({ _id: false, type: TransitOperation })
  TransitOperation?: TransitOperation;

  @prop({ _id: false, type: [Authorisation] })
  Authorisation?: Authorisation[];

  @prop({ _id: false, type: TransitCustomsOffice })
  CustomsOfficeOfDeparture?: TransitCustomsOffice;

  @prop({ _id: false, type: TransitCustomsOffice })
  CustomsOfficeOfDestinationDeclared?: TransitCustomsOffice;

  @prop({ _id: false, type: TransitCustomsOffice })
  CustomsOfficeOfDestinationActual?: TransitCustomsOffice;

  @prop({ _id: false, type: TraderAtDestination })
  TraderAtDestination?: TraderAtDestination;

  @prop({ _id: false, type: [TransitCustomsOffice] })
  CustomsOfficeOfTransitDeclared?: TransitCustomsOffice[];

  @prop({ _id: false, type: [TransitCustomsOffice] })
  CustomsOfficeOfExitForTransitDeclared?: TransitCustomsOffice[];

  @prop({ _id: false, type: HolderOfTheTransitProcedure })
  HolderOfTheTransitProcedure?: HolderOfTheTransitProcedure;

  @prop({ _id: false, type: Representative })
  Representative?: Representative;

  @prop({ _id: false, type: [Guarantee] })
  Guarantee?: Guarantee[];

  @prop({ _id: false, type: UnloadingRemark })
  UnloadingRemark?: UnloadingRemark;

  @prop({ _id: false, type: TransitConsignment })
  Consignment?: TransitConsignment;

  @prop({ _id: false, type: TransitCustomsState })
  customsState?: TransitCustomsState;

  @prop({ _id: false, type: Enquiry })
  enquiry?: Enquiry;
}

export const isDeparture = (transit: Transit) => transit.generalInfo.group === TransitGroup.DEPARTURE;

export const isArrival = (transit: Transit) => transit.generalInfo.group === TransitGroup.ARRIVAL;
