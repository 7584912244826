/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable no-restricted-syntax */
/* eslint-disable default-case */
import { FormikProps } from 'formik';
import { useMemo, useRef, useState } from 'react';
import DataTable, { IDataTableColumn } from 'react-data-table-component';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { TransitGroup, TransitHouseConsignment } from '@e-origin/shared';

import { TableActionsIcons } from '../../../../../assets';
import { Button, CustomTableActionsComponent, NotificationModal, SearchBox } from '../../../../../components';
import { selectTransit } from '../../../../../stores/transitsSlice';
import { TransitDetailsFormProps } from '../../../transit-details.component';
import * as GeneralStyle from '../transit-details-house.style';
import { HouseModal } from './house-modal/house-modal';

interface TransitDetailsHouseProps {
  form: FormikProps<TransitDetailsFormProps>;
  editDisabled: boolean;
}

export const HouseDetails: React.FC<TransitDetailsHouseProps> = ({ form, editDisabled }) => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);

  const transit = useSelector(selectTransit);

  const routeLocation = useLocation();
  const queryParams = useRef<URLSearchParams>(new URLSearchParams(routeLocation.search));
  const history = useHistory();
  const isArrival = form.values.generalInfo?.group === TransitGroup.ARRIVAL;

  const [searchQuery, setSearchQuery] = useState(
    queryParams.current.get('search') ? queryParams.current.get('search') : '',
  );
  const selectedRow = useRef<{ houseConsigmentIdx: number; houseConsigment: TransitHouseConsignment } | null>(null);

  const houseConsigments = useMemo(
    () =>
      (form.values.Consignment.HouseConsignment || []).filter((house) => {
        if (!searchQuery) {
          return true;
        }

        return house.ConsignmentItem.find(
          (item) =>
            item.Commodity?.descriptionOfGoods?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.Commodity?.CommodityCode?.hsCode?.toLowerCase().includes(searchQuery.toLowerCase()),
        );
      }),
    [form.values.Consignment.HouseConsignment, searchQuery],
  );

  const actionList = (row: TransitHouseConsignment) => {
    return [
      {
        icon: TableActionsIcons.VIEW,
        text: 'Edit',
        onClick: () => {
          setShowModal(true);
        },
      },
      {
        icon: TableActionsIcons.LAYERS,
        text: 'View Goods Items',
        onClick: () => {
          queryParams.current.set('context', 'GOODS');
          queryParams.current.set('houseRef', form.values.Consignment.HouseConsignment.indexOf(row).toString());
          history.push({ search: queryParams.current.toString() });
        },
      },
      {
        icon: TableActionsIcons.DELETE,
        text: 'Delete',
        onClick: () => {
          setShowDeleteModal(true);
        },
      },
    ];
  };

  const columns: IDataTableColumn<TransitHouseConsignment>[] = [
    {
      name: 'Consignor Id',
      cell: (row) => transit.Consignment?.Consignor?.identificationNumber || row.Consignor?.identificationNumber,
      sortable: false,
    },
    {
      name: 'Consignor name',
      cell: (row) => transit.Consignment?.Consignor?.name || row.Consignor?.name,
      sortable: false,
    },
    {
      name: 'Consignee Id',
      cell: (row) => transit.Consignment?.Consignee?.identificationNumber || row.Consignee?.identificationNumber,
      sortable: false,
    },
    {
      name: 'Goods item',
      cell: (row) => row.ConsignmentItem?.length || 0,
      sortable: false,
    },
    {
      name: 'Consignee name',
      cell: (row) => transit.Consignment?.Consignee?.name || row.Consignee?.name,
      sortable: false,
    },
    {
      name: 'Country Of Dispatch',
      cell: (row) => row.countryOfDispatch,
      sortable: false,
      omit: isArrival,
    },
    {
      name: 'Reference Number UCR',
      cell: (row) => row.referenceNumberUCR,
      sortable: false,
      omit: isArrival,
    },
    {
      name: 'Gross mass',
      cell: () => 'N/A',
      sortable: false,
    },
    {
      name: 'Actions',
      right: true,
      cell: (row) => (
        <CustomTableActionsComponent
          actions={actionList(row)}
          secondaryActions={[]}
          onClick={() => {
            selectedRow.current = {
              houseConsigmentIdx: houseConsigments.indexOf(row),
              houseConsigment: row,
            };
          }}
          disableAfterClick={true}
        />
      ),
    },
  ];

  const handleSave = (houseConsignment) => {
    const { houseConsigmentIdx = -1 } = selectedRow.current || {};

    if (houseConsigmentIdx >= 0) {
      const oldHouse = form.values.Consignment.HouseConsignment[houseConsigmentIdx];
      form.setFieldValue(`Consignment.HouseConsignment.${houseConsigmentIdx}`, {
        ...houseConsignment,
        ConsignmentItem: oldHouse.ConsignmentItem,
      });
    } else {
      form.setFieldValue('Consignment.HouseConsignment', [
        houseConsignment,
        ...form.values.Consignment.HouseConsignment,
      ]);
    }

    setShowModal(false);
    selectedRow.current = null;
  };

  const handleDocumentDelete = () => {
    const { houseConsigmentIdx } = selectedRow.current;
    form.setFieldValue(
      'Consignment.HouseConsignment',
      form.values.Consignment.HouseConsignment.filter((_, idx) => idx !== houseConsigmentIdx),
    );
    setShowDeleteModal(false);
    selectedRow.current = null;
  };

  const handleHide = () => {
    setShowModal(false);
    selectedRow.current = null;
  };

  const handleHideWarningModal = () => {
    setShowWarningModal(false);
    selectedRow.current = null;
  };

  const handleSearch = (value: string) => {
    setSearchQuery(value);

    queryParams.current.set('search', value);
    history.push({ search: queryParams.current.toString() });
  };

  const handleAddNew = () => {
    if (
      houseConsigments.length === 1 &&
      (transit.Consignment.Consignor?.identificationNumber || transit.Consignment.Consignor?.name) &&
      !houseConsigments[0].Consignor?.identificationNumber &&
      !houseConsigments[0].Consignor?.name
    ) {
      setShowWarningModal(true);
    } else {
      setShowModal(true);
    }
  };

  return (
    <>
      <GeneralStyle.ActionButton>
        <SearchBox
          handleSearch={handleSearch}
          value={searchQuery}
          placeholder="Search goods by HS code, description of goods"
        />
        {!editDisabled && (
          <Button outline onClick={handleAddNew}>
            Add new
          </Button>
        )}
      </GeneralStyle.ActionButton>
      <GeneralStyle.TableWrapper>
        <DataTable columns={columns} data={houseConsigments} pagination paginationPerPage={10} />
      </GeneralStyle.TableWrapper>
      {showModal && (
        <HouseModal
          houseConsignment={selectedRow.current?.houseConsigment}
          onSave={handleSave}
          onHide={handleHide}
          editDisabled={editDisabled}
        />
      )}
      <NotificationModal
        title="Cannot create a house consignment"
        show={showWarningModal}
        onHide={handleHideWarningModal}
        isDelete
      >
        <div>
          To create a new house consignment, the first house consignment must have a consignor, and the consignor should
          not be defined in the Persons tab.
        </div>
      </NotificationModal>
      <NotificationModal
        title="Confirm document delete"
        confirmButtonText="Delete"
        show={showDeleteModal}
        onHide={handleHide}
        onConfirm={handleDocumentDelete}
        isDelete
      >
        <div>Are you sure you want to delete this house consignment?</div>
      </NotificationModal>
    </>
  );
};
