/* eslint-disable no-param-reassign */
/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable max-lines */
import { FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { MultilingualCodeSystems, TransitGroup } from '@e-origin/shared';

import { Autocomplete, Input, MultiLangCodes } from '../../../components';
import { selectDeclarantGoodsLocations } from '../../../stores/declarantSlice';
import { FormContainer, FormRow, FormSection, FormSectionTitle } from '../../../styles/common';
import { authorisationTypes } from '../../customer-details/customer-details-transit-auth/authorisationTypes';
import { TransitCustomerInput, TransitDuplicateRow } from '../components';
import TransitDetailsTransportEquipment from '../transit-details-transport-equipment/transit-details-transport-equipment.component';
import { TransitDetailsFormProps } from '../transit-details.component';

interface TransitGeneralInfoProps {
  form: FormikProps<TransitDetailsFormProps>;
  editDisabled: boolean;
}

export const TransitGeneralInfo: React.FC<TransitGeneralInfoProps> = ({ form, editDisabled }) => {
  const goodsLocations = useSelector(selectDeclarantGoodsLocations);
  const [unlocodes, setUnlocodes] = useState([]);
  const [availableAuthorisationTypes, setAvailableAuthorisationTypes] = useState([...authorisationTypes]);

  const isArrival = form.values.generalInfo?.group === TransitGroup.ARRIVAL;

  const handleUNLocodeChange = ({ value }) => {
    form.setFieldValue('Consignment.LocationOfGoods.UNLocode', value);

    const selectedGoodsLocation = goodsLocations.find((code) => code.unLocode === value);
    form.setFieldValue('Consignment.LocationOfGoods.typeOfLocation', selectedGoodsLocation?.type || '');
    form.setFieldValue(
      'Consignment.LocationOfGoods.qualifierOfIdentification',
      selectedGoodsLocation?.qualifierOfIdentification || '',
    );
    form.setFieldValue(
      'Consignment.LocationOfGoods.additionalIdentifier',
      selectedGoodsLocation?.additionalIdentifier || '',
    );
    form.setFieldValue('Consignment.LocationOfGoods.Address', {
      city: selectedGoodsLocation?.address?.city || '',
      country: selectedGoodsLocation?.address?.country || '',
      postcode: selectedGoodsLocation?.address?.postcode || '',
      streetAndNumber: selectedGoodsLocation
        ? `${selectedGoodsLocation?.address.street} ${selectedGoodsLocation?.postcode?.houseNumber}`
        : '',
    });
  };

  useEffect(() => {
    if (goodsLocations?.length) {
      setUnlocodes(goodsLocations.map((loc) => ({ label: loc.unLocode, value: loc.unLocode })));

      handleUNLocodeChange({ value: form.values.Consignment?.LocationOfGoods?.UNLocode });
    }
  }, [goodsLocations]);

  useEffect(() => {
    const authTypes = authorisationTypes.filter(
      (authType) =>
        !form.values.Authorisation.some((auth) => auth.type === authType.value) &&
        (form.values.generalInfo.group === TransitGroup.DEPARTURE
          ? authType.value !== 'C522'
          : !['C521', 'C524'].includes(authType.value)),
    );
    setAvailableAuthorisationTypes(authTypes);
  }, [form.values.Authorisation]);

  return (
    <FormContainer>
      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>General</FormSectionTitle>
        <FormRow>
          {!isArrival && (
            <Input
              name="Consignment.referenceNumberUCR"
              placeholder="Reference number/UCR"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Consignment.referenceNumberUCR}
              width={50}
              widthUnit="%"
              disabled={editDisabled}
            />
          )}
          {!isArrival && (
            <MultiLangCodes
              name="TransitOperation.additionalDeclarationType"
              placeholder="Additional declaration"
              onChange={form.handleChange}
              system={MultilingualCodeSystems.NCTS}
              code="CL042"
              value={form.values.TransitOperation.additionalDeclarationType}
              disabled={editDisabled}
              width={55}
            />
          )}
          <MultiLangCodes
            name="TransitOperation.security"
            placeholder="Security"
            onChange={form.handleChange}
            system={MultilingualCodeSystems.NCTS}
            code="CL217"
            value={form.values.TransitOperation.security}
            disabled={editDisabled}
            width={isArrival ? 31.5 : 55}
          />
          {!isArrival && (
            <MultiLangCodes
              name="TransitOperation.specificCircumstanceIndicator"
              placeholder="Specific circumstance indicator"
              onChange={form.handleChange}
              system={MultilingualCodeSystems.NCTS}
              code="CL296"
              value={form.values.TransitOperation.specificCircumstanceIndicator}
              disabled={editDisabled}
            />
          )}
          {!isArrival && (
            <MultiLangCodes
              name="TransitOperation.bindingItinerary"
              placeholder="Binding itinerary"
              onChange={form.handleChange}
              system={MultilingualCodeSystems.NCTS}
              code="CL027"
              value={form.values.TransitOperation?.bindingItinerary}
              disabled={editDisabled}
              width={93}
            />
          )}
        </FormRow>
        <FormRow>
          {!isArrival && (
            <MultiLangCodes
              name="CustomsOfficeOfDeparture.referenceNumber"
              placeholder="Customs office of departure"
              onChange={form.handleChange}
              system={MultilingualCodeSystems.NCTS}
              code="CustomsOfficeDeparture"
              value={form.values.CustomsOfficeOfDeparture?.referenceNumber}
              disabled={editDisabled}
              width={93}
            />
          )}

          {!isArrival && (
            <MultiLangCodes
              name="CustomsOfficeOfDestinationDeclared.referenceNumber"
              placeholder="Customs office of destination"
              onChange={form.handleChange}
              system={MultilingualCodeSystems.NCTS}
              code="CustomsOfficeDestination"
              value={form.values.CustomsOfficeOfDestinationDeclared?.referenceNumber}
              disabled={editDisabled}
              width={93}
            />
          )}
          {isArrival && (
            <MultiLangCodes
              name="CustomsOfficeOfDestinationActual.referenceNumber"
              placeholder="Customs office of destination actual"
              onChange={form.handleChange}
              system={MultilingualCodeSystems.NCTS}
              code="CustomsOfficeDestination"
              value={form.values.CustomsOfficeOfDestinationActual?.referenceNumber}
              disabled={editDisabled}
              width={93}
            />
          )}
          <MultiLangCodes
            name="Consignment.countryOfDestination"
            placeholder="Country of destination"
            onChange={form.handleChange}
            system={MultilingualCodeSystems.NCTS}
            code="CountryCodesCommonTransit"
            value={form.values.Consignment?.countryOfDestination}
            disabled={editDisabled}
          />
          {!isArrival && (
            <MultiLangCodes
              name="Consignment.countryOfDispatch"
              placeholder="Country of dispatch"
              onChange={form.handleChange}
              system={MultilingualCodeSystems.NCTS}
              code="CountryCodesCommonTransit"
              value={form.values.Consignment?.countryOfDispatch}
              disabled={editDisabled}
            />
          )}
          <Input
            name="Consignment.grossMass"
            placeholder="Gross mass"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Consignment?.grossMass}
            width={93}
            widthUnit="%"
            disabled={editDisabled}
          />
        </FormRow>
      </FormSection>

      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle>Customer</FormSectionTitle>
        <FormRow>
          <TransitCustomerInput form={form} disabled={editDisabled} />
        </FormRow>
      </FormSection>

      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Authorisations</FormSectionTitle>
        <TransitDuplicateRow
          addButtonLabel="Add new authorisation"
          form={form}
          inputs={[
            {
              type: 'Autocomplete',
              label: 'Authorisation Type',
              name: 'type',
              width: 24,
              search: (searchText) =>
                availableAuthorisationTypes.filter((authorisationType) =>
                  authorisationType.value.includes(searchText),
                ) as any,
            },
            {
              type: 'Input',
              name: 'referenceNumber',
              label: 'Authorization referenceNumber',
              width: 24,
            },
          ]}
          arrayPath="Authorisation"
          disabled={editDisabled}
          maxRows={5}
        />
      </FormSection>

      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Location of Goods</FormSectionTitle>
        <FormRow>
          <Autocomplete
            placeholder="UNLOCODE / Warehouse ID"
            fetchOptions={(search: string) => unlocodes.filter((code) => code.value.includes(search)) as any}
            onChange={handleUNLocodeChange}
            value={{
              value: form.values.Consignment?.LocationOfGoods?.UNLocode,
              label: form.values.Consignment?.LocationOfGoods?.UNLocode,
            }}
            disabled={editDisabled}
          />
          <Input
            placeholder="Type of location"
            value={form.values.Consignment?.LocationOfGoods?.typeOfLocation}
            width={93}
            widthUnit="%"
            disabled={true}
            defaultBehaviour
          />
          <Input
            placeholder="Qualifier of identification"
            value={form.values.Consignment?.LocationOfGoods?.qualifierOfIdentification}
            width={93}
            widthUnit="%"
            disabled={true}
            defaultBehaviour
          />
          <Input
            placeholder="Additional Identifier"
            value={form.values.Consignment?.LocationOfGoods?.additionalIdentifier}
            width={93}
            widthUnit="%"
            disabled={true}
            defaultBehaviour
          />
        </FormRow>
        <FormRow>
          <Input
            placeholder="Street and Number"
            value={form.values.Consignment?.LocationOfGoods?.Address?.streetAndNumber}
            width={50}
            widthUnit="%"
            disabled={true}
            defaultBehaviour
          />
          <Input
            placeholder="Postcode"
            value={form.values.Consignment?.LocationOfGoods?.Address?.postcode}
            width={50}
            widthUnit="%"
            disabled={true}
            defaultBehaviour
          />
          <Input
            placeholder="City"
            value={form.values.Consignment?.LocationOfGoods?.Address?.city}
            width={50}
            widthUnit="%"
            disabled={true}
            defaultBehaviour
          />
          <Input
            placeholder="Country"
            value={form.values.Consignment?.LocationOfGoods?.Address?.country}
            width={50}
            widthUnit="%"
            disabled={true}
            defaultBehaviour
          />
        </FormRow>
      </FormSection>

      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Container</FormSectionTitle>
        <FormRow>
          <TransitDetailsTransportEquipment form={form} disabled={editDisabled} />
        </FormRow>
      </FormSection>

      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Transport</FormSectionTitle>
        <FormRow>
          <MultiLangCodes
            width={24}
            name="Consignment.inlandModeOfTransport"
            placeholder="Inland mode of transport"
            onChange={form.handleChange}
            system={MultilingualCodeSystems.NCTS}
            code="TransportModeCode"
            value={form.values.Consignment?.inlandModeOfTransport}
            disabled={editDisabled}
          />
          {!isArrival && (
            <MultiLangCodes
              width={24}
              name="Consignment.modeOfTransportAtTheBorder"
              placeholder="Mode of transport at the border"
              onChange={form.handleChange}
              system={MultilingualCodeSystems.NCTS}
              code="TransportModeCode"
              value={form.values.Consignment?.modeOfTransportAtTheBorder}
              disabled={editDisabled}
            />
          )}
        </FormRow>
      </FormSection>

      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Departure transport means</FormSectionTitle>
        <TransitDuplicateRow
          addButtonLabel="Add Transport Means"
          form={form}
          inputs={[
            {
              type: 'MultiLang',
              label: 'Transport ID Type',
              name: 'typeOfIdentification',
              code: 'CL750',
              width: 24,
            },
            {
              type: 'Input',
              name: 'identificationNumber',
              label: 'Identification number',
              width: 24,
            },
            {
              type: 'MultiLang',
              label: 'Nationality',
              name: 'nationality',
              width: 24,
              code: 'CL165',
            },
          ]}
          arrayPath="Consignment.DepartureTransportMeans"
          disabled={editDisabled}
        />
      </FormSection>

      {!isArrival && (
        <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
          <FormSectionTitle noTopBorder>Active border transport means</FormSectionTitle>
          <TransitDuplicateRow
            addButtonLabel="Add Active Transport"
            form={form}
            inputs={[
              {
                type: 'MultiLang',
                name: 'customsOfficeAtBorderReferenceNumber',
                label: 'Customs office at the border',
                width: 24,
                code: 'CL141',
              },
              {
                type: 'MultiLang',
                label: 'Transport ID Type',
                name: 'typeOfIdentification',
                width: 24,
                code: 'TypeOfIdentificationofMeansOfTr',
              },
              {
                type: 'Input',
                name: 'identificationNumber',
                label: 'Identification number',
                width: 24,
              },
              {
                type: 'MultiLang',
                label: 'Nationality',
                name: 'nationality',
                width: 15,
                code: 'CL165',
              },
              {
                type: 'Input',
                name: 'conveyanceReferenceNumber',
                label: 'Conveyance reference number',
                width: 33,
              },
            ]}
            arrayPath="Consignment.ActiveBorderTransportMeans"
            disabled={editDisabled}
          />
        </FormSection>
      )}

      {!isArrival && (
        <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
          <FormSectionTitle noTopBorder>Place of loading</FormSectionTitle>
          <FormRow>
            <MultiLangCodes
              name="Consignment.PlaceOfLoading.UNLocode"
              placeholder="UNLOCODE"
              onChange={form.handleChange}
              system={MultilingualCodeSystems.NCTS}
              code="Unlocode"
              value={form.values.Consignment?.PlaceOfLoading?.UNLocode}
              disabled={editDisabled}
              width={24}
            />
            <MultiLangCodes
              name="Consignment.PlaceOfLoading.country"
              placeholder="Country"
              onChange={form.handleChange}
              system={MultilingualCodeSystems.NCTS}
              code="CountryCodesCommonTransit"
              value={form.values.Consignment?.PlaceOfLoading?.country}
              disabled={editDisabled}
              width={24}
            />
            <Input
              name="Consignment.PlaceOfLoading.location"
              placeholder="Location"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Consignment?.PlaceOfLoading?.location}
              width={24}
              widthUnit="%"
              disabled={editDisabled}
            />
          </FormRow>
        </FormSection>
      )}

      {!isArrival && (
        <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
          <FormSectionTitle noTopBorder>Place of Unloading</FormSectionTitle>
          <FormRow>
            <MultiLangCodes
              name="Consignment.PlaceOfUnloading.UNLocode"
              placeholder="UNLOCODE"
              onChange={form.handleChange}
              system={MultilingualCodeSystems.NCTS}
              code="Unlocode"
              value={form.values.Consignment?.PlaceOfUnloading?.UNLocode}
              disabled={editDisabled}
              width={24}
            />
            <MultiLangCodes
              name="Consignment.PlaceOfUnloading.country"
              placeholder="Country"
              onChange={form.handleChange}
              system={MultilingualCodeSystems.NCTS}
              code="CountryCodesCommonTransit"
              value={form.values.Consignment?.PlaceOfUnloading?.country}
              disabled={editDisabled}
              width={24}
            />
            <Input
              name="Consignment.PlaceOfUnloading.location"
              placeholder="Location"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Consignment?.PlaceOfUnloading?.location}
              width={24}
              widthUnit="%"
              disabled={editDisabled}
            />
          </FormRow>
        </FormSection>
      )}

      {!isArrival && (
        <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
          <FormSectionTitle noTopBorder>Customs office of transit</FormSectionTitle>
          <TransitDuplicateRow
            addButtonLabel="Add"
            form={form}
            inputs={[
              {
                type: 'MultiLang',
                name: 'referenceNumber',
                label: 'Customs office at the border',
                width: 24,
                code: 'CustomsOfficeTransit',
              },
              {
                type: 'Input',
                name: 'arrivalDateAndTimeEstimated',
                label: 'Deadline',
                width: 24,
              },
            ]}
            arrayPath="CustomsOfficeOfTransitDeclared"
            disabled={editDisabled}
          />
        </FormSection>
      )}

      {!isArrival && (
        <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
          <FormSectionTitle noTopBorder>Transport charges</FormSectionTitle>
          <FormRow>
            <MultiLangCodes
              width={24}
              name="Consignment.TransportCharges.methodOfPayment"
              placeholder="Method of payment"
              onChange={form.handleChange}
              system={MultilingualCodeSystems.NCTS}
              code="MethodOfPayment"
              value={form.values.Consignment?.TransportCharges?.methodOfPayment}
              disabled={editDisabled}
            />
          </FormRow>
        </FormSection>
      )}
    </FormContainer>
  );
};
